const image = [
  {
    key: 1,
    titleDe: "Fabrikplanung & Optimierung",
    titleEng: "Factory planning & optimization",
    contentDe:
      "Wir bieten maßgeschneiderte Lösungen zur Planung und Optimierung von Produktionsstätten, um Kosten zu senken und die Umweltbelastung zu minimieren.",
    contentEng:
      "We provide customized solutions for plant design and optimization to reduce costs and minimize environmental impact."
  },
  {
    key: 2,
    titleDe: "Prozess- und Ressourceneffizienz",
    titleEng: "Process & resource efficiency",
    contentDe:
      "Steigern Sie Ihre Effizienz und senken Sie die Kosten durch optimierte Prozesse und Ressourcennutzung.",
    contentEng:
      "Increase efficiency and reduce costs through optimized processes and resource use."
  },
  {
    key: 3,
    titleDe: "Nachhaltige Supply-Chain-Strategien",
    titleEng: "Sustainable supply chain strategies",
    contentDe:
      "Wir entwickeln nachhaltige Supply-Chain-Strategien, die Ihre Produktionsprozesse umweltfreundlicher machen.",
    contentEng:
      "We develop sustainable supply chain strategies to make your production processes more environmentally friendly."
  },
  {
    key: 4,
    titleDe: "Beratung zur zirkulären Wirtschaft",
    titleEng: "Advice on the circular economy",
    contentDe:
      "Wir helfen Ihnen, Ihre Produktionsprozesse durch Recycling und Wiederverwendung zukunftsfähig und nachhaltig zu gestalten."
      ,
    contentEng:
      "We help make your production processes sustainable and future-proof through recycling and reuse."
  }
];

export default image;