import { PopupWidget } from 'react-calendly';

const PopupComponent = () => {
  return (
    <div className="popup-widget">
      <PopupWidget
        url="https://calendly.com/casparbaron" 
        rootElement={document.getElementById('root')}
        text="get in contact"
        textColor="#6BAF92"
        color="#ffffff"
        border-radius="20px"
      />
    </div>
  );
};

export default PopupComponent;
